import React from 'react';
import api from '../../lib/Api';
import authentication from '../../lib/AuthenticationManager';
import Segment from '../../lib/models/Segment';
import './SegmentDetails.css';
import Installation from '../../lib/models/Installation';
import SegmentPanel from './panels/SegmentPanel';
import ConfirmPanel from './panels/ConfirmPanel';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { RemoveCauselinkInstanceCommand, RemoveDatabaseCommand, RemoveFromUptimeRobot } from '../../lib/models/RemoteCommand';
import InstallationAndStatistic from '../../lib/models/InstallationAndStatistic';
import InstallationStatistic from '../../lib/models/InstallationStatistic';

class ViewState {
    public showEditSegmentPanel: boolean = false;
    public confirmDeleteSegment: boolean = false;
    public confirmDeleteInstallation: boolean = false;
    public showAWSDetails: boolean = false;
    public showMore: Map<string, boolean> = new Map<string, boolean>();

    public constructor(init?: Partial<ViewState>) {
        Object.assign(this, init);
    }
}

interface Props extends RouteComponentProps {
    id?: string;
}

interface State {
    segment: Segment;
    installationAndStatistics: InstallationAndStatistic[];
    totals: InstallationStatistic;
    averages: InstallationStatistic;
    viewState: ViewState;
    targetInstallation: Installation | undefined;
    /** Sends remove instance command when deleting instance */
    sendRemoveInstanceCommand: boolean;
    sendRemoveDatabaseCommand: boolean;
    sendRemoveFromUptimeRobotCommand: boolean;
}

export default class SegmentDetails extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            segment: new Segment(),
            installationAndStatistics: [],
            totals: new InstallationStatistic(),
            averages: new InstallationStatistic(),
            viewState: new ViewState(),
            targetInstallation: undefined,
            sendRemoveInstanceCommand: true,
            sendRemoveDatabaseCommand: true,
            sendRemoveFromUptimeRobotCommand: true
        };

        api.segments.find(this.props.id!).then(async (segment) => {
            this.setState({ segment });

            const installationAndStatistics = await api.segments.listInstallations(segment);
            const totals = new InstallationStatistic();
            const averages = new InstallationStatistic()
;
            totals.activeUsers30Days = installationAndStatistics.map(ias => ias.statistic?.activeUsers30Days ?? 0).reduce((pv, cv) => pv + cv);
            totals.causeCount = installationAndStatistics.map(ias => ias.statistic?.causeCount ?? 0).reduce((pv, cv) => pv + cv);
            totals.concurrentUsersAvg = installationAndStatistics.map(ias => ias.statistic?.concurrentUsersAvg ?? 0).reduce((pv, cv) => pv + cv);
            totals.rcaCount = installationAndStatistics.map(ias => ias.statistic?.rcaCount ?? 0).reduce((pv, cv) => pv + cv);
            totals.usersCount = installationAndStatistics.map(ias => ias.statistic?.usersCount ?? 0).reduce((pv, cv) => pv + cv);

            averages.activeUsers30Days = Math.ceil(totals.activeUsers30Days / installationAndStatistics.length);
            averages.causeCount = Math.ceil(totals.causeCount / installationAndStatistics.length);
            averages.concurrentUsersAvg = Math.ceil(totals.concurrentUsersAvg / installationAndStatistics.length);
            averages.rcaCount = Math.ceil(totals.rcaCount / installationAndStatistics.length);
            averages.usersCount = Math.ceil(totals.usersCount / installationAndStatistics.length);

            this.setState({ installationAndStatistics, totals, averages });
        });
    }

    public render() {
        return <div id="SegmentDetails" className='section container'>
            <div className='block is-flex is-justify-content-space-between is-align-items-flex-start'>
                <div>
                    <h1 className='title'>Environment: {this.state.segment.name}</h1>
                    <h2 className='subtitle'>Segment: {this.state.segment.environment} {this.state.segment.region}</h2>
                </div>
                {authentication.isAdmin && this.renderAdminSegmentMenu()}
            </div>
            {this.renderDetails()}
        </div>;
    }

    private renderDetails() {
        if (this.state.viewState.confirmDeleteSegment) {
            return <ConfirmPanel
                action={`delete segment ${this.state.segment!.name}`}
                onCancel={this.hideOverlays.bind(this)}
                onConfirm={() => {
                    api.segments.delete(this.state.segment).then(() => navigate('installations'));

                    // this.setState({ viewState: new ViewState({ wait: true }) });
                }} />;
        }

        if (this.state.viewState.confirmDeleteInstallation) {
            return <div>
                <ConfirmPanel
                    action={`delete ${this.state.targetInstallation!.name}`} // vs mac has some syntax issues ... `
                    onCancel={this.hideOverlays.bind(this)}
                    onConfirm={() => {
                        const promises = [];

                        if (this.state.sendRemoveFromUptimeRobotCommand) {
                            promises.push(api.commands.process(new RemoveFromUptimeRobot(this.state.targetInstallation!.id)));
                        }

                        if (this.state.sendRemoveInstanceCommand) {
                            promises.push(api.commands.process(new RemoveCauselinkInstanceCommand(this.state.targetInstallation!.id)));
                        }

                        if (this.state.sendRemoveDatabaseCommand) {
                            promises.push(api.commands.process(new RemoveDatabaseCommand(this.state.targetInstallation!.id)));
                        }

                        // wait for the commands to complete before pulling the rug out from under
                        Promise.all(promises).then(() => {
                            api.installations.delete(this.state.targetInstallation!).then(() =>
                                api.segments.listInstallations(this.state.segment).then((installationAndStatistics) => this.setState({ installationAndStatistics, viewState: new ViewState() })));
                        });

                        // this.setState({ targetInstallation: undefined, viewState: new ViewState({ wait: true }) });
                    }} />
                    <label className='checkbox block'>
                        <input type='checkbox' checked={this.state.sendRemoveFromUptimeRobotCommand} onChange={(ev) => this.setState({ sendRemoveFromUptimeRobotCommand: ev.target.checked })}></input>
                        Remove From Uptime Robot
                    </label>
                    <label className='checkbox block'>
                        <input type='checkbox' checked={this.state.sendRemoveInstanceCommand} onChange={(ev) => this.setState({ sendRemoveInstanceCommand: ev.target.checked })}></input>
                        Remove Causelink Instance (invoke lambda)
                    </label>
                    <label className='checkbox block'>
                        <input type='checkbox' checked={this.state.sendRemoveDatabaseCommand} onChange={(ev) => this.setState({ sendRemoveDatabaseCommand: ev.target.checked })}></input>
                        Remove Causelink Database
                    </label>
                </div>
        }

        if (this.state.viewState.showEditSegmentPanel) {
            return <SegmentPanel
                segment={this.state.segment}
                isAdd={false}
                onHide={() => this.hideOverlays()}
                onUpdate={() => { api.segments.update(this.state.segment); this.hideOverlays(); }} />;
        }

        return <div>
            <div className='overview'>
                <div className='block'>{this.renderAWSSettings()}</div>
                <div>{this.renderStatisticsTable(this.state.totals, 'Totals')}</div>
                <div>{this.renderStatisticsTable(this.state.averages, 'Averages')}</div>
            </div>

            <div className='block'>{this.renderInstallations()}</div>
        </div>;
    }

    private renderAdminSegmentMenu() {
        const deleteMenuItemProps: any = {};
        if (this.state.installationAndStatistics.length > 0) {
            deleteMenuItemProps.disabled = true;
            deleteMenuItemProps.title = 'segment cannot be deleted while it contains instances';
        }

        return (
            <Menu>
                {({ isExpanded }) => {
                    return <>
                        <MenuButton className={`dropdown-trigger is-pulled-right no-button is-size-5`}><FontAwesomeIcon icon={faEllipsisH} /></MenuButton>
                        <MenuList className='dropdown-content'>
                            <MenuItem className='dropdown-item' onSelect={() => this.setState({ viewState: new ViewState({ showEditSegmentPanel: true }) })}>Edit</MenuItem>
                            <MenuItem className='dropdown-item' {...deleteMenuItemProps} onSelect={() => this.setState({ viewState: new ViewState({ confirmDeleteSegment: true }) })}>Delete</MenuItem>
                        </MenuList>
                    </>
                }}
            </Menu>);
    }

    private renderAWSSettings() {
        const flip = () => {
            const viewState = {...this.state.viewState};
            viewState.showAWSDetails = !viewState.showAWSDetails;

            this.setState({ viewState });
        };

        return <div className="aws-settings">
            <table className='table is-bordered is-striped'>
                <thead>
                    <tr>
                        <th colSpan={2}>
                            <span>AWS Settings</span>

                            <div>{this.state.viewState.showAWSDetails
                                ? <button onClick={flip}>Show Less</button>
                                : <button onClick={flip}>Show More</button>}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr><td>Domain</td><td>{this.state.segment.domain}</td></tr>
                    <tr><td>Default Version</td><td>{this.state.segment.defaultVersion}</td></tr>
                    {this.state.viewState.showAWSDetails
                        ?
                            <>
                                <tr><td>AutoScaling Group Name</td><td>{this.state.segment.autoScalingGroup}</td></tr>
                                <tr><td>Simple Queue Service URL</td><td>{this.state.segment.sqsurl}</td></tr>
                                <tr><td>ElasticSearch</td><td>{this.state.segment.elasticSearch}</td></tr>
                                <tr><td>Application Balancer ARN</td><td>{this.state.segment.balancerArn}</td></tr>
                                <tr><th colSpan={2}>CloudFront</th></tr>
                                <tr><td>ACM ARN</td><td>{this.state.segment.cfacmarn}</td></tr>
                                <tr><td>Edge Lambda ARN</td><td>{this.state.segment.cfEdgeLambdaArn}</td></tr>
                            </>
                        : <></>}
                </tbody>
            </table>
        </div>
    }

    private renderStatisticsTable(statistic: InstallationStatistic, label: string) {
        return <table className='table is-bordered is-striped'>
                <thead>
                    <tr>
                        <th colSpan={2}>{label}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>RCAs</th>
                        <td>{statistic.rcaCount}</td>
                    </tr>
                    <tr>
                        <th>Causes</th>
                        <td>{statistic.causeCount}</td>
                    </tr>
                    <tr>
                        <th>Users</th>
                        <td>{statistic.usersCount}</td>
                    </tr>
                    <tr>
                        <th>Active Users 30 Days</th>
                        <td>{statistic.activeUsers30Days}</td>
                    </tr>
                    <tr>
                        <th>Concurrent Users</th>
                        <td>{statistic.concurrentUsersAvg}</td>
                    </tr>
                </tbody>
            </table>
    }

    private renderInstallations() {
        const toggle = (installationId: string) => {
            const viewState = {...this.state.viewState};
            const showMore = new Map<string, boolean>(this.state.viewState.showMore);

            showMore.set(installationId, !(showMore.has(installationId) && showMore.get(installationId)));
            viewState.showMore = showMore;

            this.setState({ viewState });
        };

        const setAll = (toThis: boolean) => {
            const viewState = {...this.state.viewState};
            const showMore = new Map<string, boolean>();

            this.state.installationAndStatistics.forEach(info =>
                showMore.set(info.installation!.id, toThis));

            viewState.showMore = showMore;

            this.setState({ viewState });
        };

        return <div className="block installations">
            <h3 className='subtitle'>Instances ({this.state.installationAndStatistics.length})
                <div><button onClick={() => setAll(true)}>Show All</button> <button onClick={() => setAll(false)}>Hide All</button></div>
            </h3>

            <div className="columns is-multiline">
                {this.state.installationAndStatistics.map(info =>
                    <div key={info.installation!.id} className='column is-one-third-fullhd is-half-tablet is-half-mobile'>
                        <div className="card installation">
                            <div className='card-header'>
                                <h4 className='card-header-title is-justify-content-space-between'>
                                    <span>{info.installation!.name}</span>

                                    {info.installation!.versionMatch
                                        ? <span>{info.installation!.versionMatch}</span>
                                        : <></>}

                                    <span>{info.installation!.version}</span>
                                    {authentication.isManager &&
                                        <Menu>
                                            <MenuButton className={`dropdown-trigger no-button is-size-5`}><FontAwesomeIcon className='has-text-grey-dark' icon={faEllipsisH} /></MenuButton>
                                            <MenuList className='dropdown-content'>
                                                <MenuItem className='dropdown-item' onSelect={() => navigate(`/installations/segment/${this.state.segment.id}/edit/${info.installation!.id}`)}>Edit</MenuItem>
                                                <MenuItem className='dropdown-item' onSelect={() => navigate(`/installations/segment/${this.state.segment.id}/copy/${info.installation!.id}`)}>Copy To ...</MenuItem>
                                                <MenuItem className='dropdown-item' onSelect={() => this.setState({ targetInstallation: info.installation!, viewState: new ViewState({ confirmDeleteInstallation: true }) })}>Delete</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    }
                                </h4>
                            </div>
                            <div className={'card-content ' + (info.installation!.versionMatch ? 'differentVersion' : '')}>
                                <p><a href={`https://${info.installation!.dns + '.' + this.state.segment.domain}`}>https://{info.installation!.dns + '.' + this.state.segment.domain}</a></p>

                                {this.state.viewState.showMore.has(info.installation!.id) && this.state.viewState.showMore.get(info.installation!.id)
                                    ?
                                        <div>
                                            <p><span>Last Statistic Time</span> {info.installation?.lastStatistic?.toString() ?? 'N/A'}</p>

                                            <ul>
                                                <li><span>RCAs:</span> {info.statistic?.rcaCount ?? 'N/A'}</li>
                                                <li><span>Causes:</span> {info.statistic?.causeCount ?? 'N/A'}</li>
                                                <li><span>Users:</span> {info.statistic?.usersCount ?? 'N/A'}</li>
                                                <li><span>Active Users 30 Days:</span> {info.statistic?.activeUsers30Days ?? 'N/A'}</li>
                                                <li><span>Concurrent Users:</span> {info.statistic?.concurrentUsersAvg ?? 'N/A'}</li>
                                                <li>
                                                    <span>Packages ({info.statistic?.packages?.length ?? 'none'}):</span>
                                                    {info.statistic?.packages?.map(pinfo => <div>{pinfo.name} {pinfo.version}</div>)}
                                                </li>
                                            </ul>

                                            <div><button onClick={() => toggle(info.installation!.id)}>Show Less</button></div>
                                        </div>
                                    : <div><button onClick={() => toggle(info.installation!.id)}>Show More</button></div>}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {authentication.isManager
                ? <div className='buttons is-right'>
                    <Link className='button' to={`/installations/segment/${this.state.segment.id}/sendCommands`}>Send Commands</Link>
                    <Link className='button is-primary' to={`/installations/segment/${this.state.segment.id}/addInstance`}>Add Instance</Link>
                </div>
                : ''}

        </div>;
    }

    private hideOverlays() {
        this.setState({ viewState: new ViewState(), sendRemoveInstanceCommand: true, sendRemoveDatabaseCommand: true });
    }
}