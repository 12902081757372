import PackageStatistic from "./PackageStatistic";

export default class InstallationStatistic {
    public id: string = '';
    public segmentId: string | undefined;
    public causeCount?: number;
    public concurrentUsersAvg?: number;
    public activeUsers30Days?: number;
    public usersCount?: number;
    public packages?: PackageStatistic[];
    public rcaCount?: number;

    public constructor(partial?: Partial<InstallationStatistic>) {
        Object.assign(this, partial);
    }
}